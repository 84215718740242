//FAQ
import { Button } from "./Button";
import chart from "../distribution.jpg";
import logo from "./logo.png";

export const Game = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <span style={{ color: "white" }}>
        <img src={logo} alt="logo" width="100%" />
        <br />
        <Button onClick={() => (window.location.href = "https://www.metaflip.fun")}>FLIP</Button>&nbsp;&nbsp;&nbsp;
        <Button onClick={() => (window.location.href = "https://mint.metaflip.fun")}>MINT</Button>
        <br />
        <br />
        <b>What is MetaFlip (MFC)?</b>
        <br />
        MetaFlip is a smart contract that allows users to play Double or Nothing with their BNB tokens. Odds are 50/50
        with a 3% play fee. The play fees collected are automatically redistributed evenly to MetaFlip NFT holders.
        <br />
        <br />
        <b>How do I know I can trust MetaFlip?</b>
        <br />
        MetaFlip is the #1 most trusted coin flip platform on BNB Chain and all transactions are tracked on chain and
        can be audited by anyone.
        <br />
        <br />
        <b>Where can I learn more?</b>
        <br />
        Join us on Telegram, there will always be someone to help you out.
        <br />
        <br />
        <b>How do I play?</b>
        <br />
        1. Choose Heads or Tails.
        <br />
        2. Choose your amount to play.
        <br />
        3. Check your results.
        <br />
        <br />
        <b>How are flips determined?</b>
        <br />
        All flips are determined using the blockhash method, which is a highly secure and widely accepted process within
        the blockchain community. This method relies on the cryptographic hash function of the previous block in the
        blockchain, providing an unpredictable and verifiable source of randomness. Because the hash function is
        mathematically designed to generate a unique output for each input, and because the previous block's hash is
        publicly available and unalterable, the outcome of the coin flip is determined in a way that is both random and
        verifiable. The blockhash method is a reliable and secure way to determine the outcome of coin flips and can be
        trusted to provide a fair and transparent game.
        <br />
        <br />
        <b>What happens to fees and losses?</b>
        <br />
        Fees are redistributed to MetaFlip NFT holders and losses are split 25% to development and 75% go into the
        contract, growing the minimum play amount.
        <br />
        <br />
        <img src={chart} alt="chart" width="100%" />
      </span>
      <p>
        Mint a MetaFlip NFT to get dividends from the game's tax!
        <br />
        <br />
        <i>Play at your own risk!</i>
      </p>
      <Button onClick={() => (window.location.href = "https://www.metaflip.fun")}>FLIP</Button>&nbsp;&nbsp;&nbsp;
      <Button onClick={() => (window.location.href = "https://mint.metaflip.fun")}>MINT</Button>
      <br />
      <br />
      Recent Flips:
    </div>
  );
};
